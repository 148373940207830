<template>
  <div class="registration-form">
    <div class="registration-form__info">
      <div class="registration-form__info-title">
        Увійдіть до Особистого кабінету,
      </div>
      <div class="registration-form__info-text">
        використовуючи логін та пароль, що Ви
        <br/>отримали в смс
      </div>
    </div>
    <div class="registration-form__fields">
      <div class="registration-form__field">
        <label for="user-login">Логін</label>
        <div class="registration-form__field-input">
          <input v-model="login" type="text" id="user-login"/>
          <div class="registration-form__field-error"></div>
        </div>
      </div>
      <div class="registration-form__field">
        <label for="user-password">Пароль</label>
        <div class="registration-form__field-input">
          <input
            v-model="password"
            type="password"
            id="user-password"
            placeholder="Введіть пароль..."
          />
          <div class="registration-form__field-error"></div>
        </div>
      </div>

      <div class="registration-form__options">
        <input
          type="button"
          @click="loginUser()"
          class="registration-form__submit"
          value="Увійти"
        />
      </div>
    </div>
    <form
      action="https://terapiaplus.com.ua/terapiaplus/box/login/"
      method="post"
      ref="ThemForm"
      hidden
    >
      <input type="text" name="login" v-model="login"/>
      <input type="text" name="password_lo" v-model="password"/>
      <input type="hidden" name="enter" value="true"/>
      <input type="submit" name="submit" value="true" ref="sendThemBtn"/>
    </form>
  </div>
</template>

<script>
//import MaskedInput from "vue-masked-input";
export default {
  name: "RegistrationSignIn",
  data() {
    return {
      login: "",
      password: ""
    };
  },
  components: {
    //  MaskedInput
  },
  methods: {
    loginUser() {
      let FormDataSend = new FormData()
      FormDataSend.append("login", this.login)
      FormDataSend.append("password_lo", this.password)
      FormDataSend.append("enter", "увійти")
      FormDataSend.append("action", "login")
      this.axios
        .post(
          "https://terapiaplus.com.ua/api/login",
          FormDataSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(res => {
          this.$refs.sendThemBtn.click();
        });
    }
  }
};
</script>

<style></style>
