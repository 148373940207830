<template>
  <div class="registration-info">
    <a href="tel:0 800 211 202" class="registration-info__phone"
      >0 800 211 202</a
    >
    <div class="registration-info__title">
      Якщо у Вас виникли труднощі з реєстрацією, зверніться до Адміністратора
      Програми
    </div>
    <div class="registration-info__text">
      за телефоном гарячої лінії (щоденно з 9:00 до 21:00).
    </div>
    <div class="registration-info__text">
      Дзвінки з усіх телефонних номерів в межах України безкоштовні.
    </div>
  </div>
</template>
<script>
export default {
  name: "RegistrationInfo"
};
</script>
