var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"registration"},[_c('div',{staticClass:"registration-steps"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xx-lg-3 col-xx-lg-offset-2 col-sm-4 col-xs",class:_vm.getResponsive && _vm.getRegistrationStep === 1 ? 'active' : ''},[_c('div',{staticClass:"registration-steps__item",class:[
              _vm.getRegistrationStep === 1 ? 'active' : '',
              _vm.getRegistrationStep > 1 ? 'done' : ''
            ]},[_c('div',{staticClass:"registration-steps__number"},[_vm._v("1")]),_c('div',{staticClass:"registration-steps__text"},[_vm._v(" Персональні дані ")])])]),_c('div',{staticClass:"col-xx-lg-3 col-sm-4 col-xs",class:_vm.getResponsive && _vm.getRegistrationStep === 2 ? 'active' : ''},[_c('div',{staticClass:"registration-steps__item registration-steps__item_center",class:[
              _vm.getRegistrationStep === 2 ? 'active' : '',
              _vm.getRegistrationStep > 2 ? 'done' : ''
            ]},[_c('div',{staticClass:"registration-steps__number"},[_vm._v("2")]),_c('div',{staticClass:"registration-steps__text"},[_vm._v("Підтвердження")])])]),_c('div',{staticClass:"col-xx-lg-3 col-sm-4 col-xs",class:_vm.getResponsive && _vm.getRegistrationStep === 3 ? 'active' : ''},[_c('div',{staticClass:"registration-steps__item",class:_vm.getRegistrationStep === 3 ? 'active' : ''},[_c('div',{staticClass:"registration-steps__number"},[_vm._v("3")]),_c('div',{staticClass:"registration-steps__text"},[_vm._v("Вхід до кабінету")])])])])])]),_c('div',{staticClass:"registration-process"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xx-lg-offset-2",class:_vm.getRegistrationStep === 2
              ? 'col-xx-lg-5 col-x-lg-6 col-lg-6 col-md-6 col-xs-12'
              : 'col-xx-lg-4 col-x-lg-6 col-lg-6 col-md-6 col-xs-12'},[(_vm.getRegistrationStep === 1)?_c('RegistrationForm'):_vm._e(),(_vm.getRegistrationStep === 2)?_c('RegistrationConfirm'):_vm._e(),(_vm.getRegistrationStep === 3)?_c('RegistrationSignIn'):_vm._e()],1),_c('div',{staticClass:"col-xx-lg-4 col-x-lg-5 col-lg-6 col-md-6 col-xs-12",class:_vm.getRegistrationStep !== 2
              ? 'col-xx-lg-offset-1 col-x-lg-offset-1'
              : ''},[_c('div',{staticClass:"registration-process__info"},[_c('RegistrationInfo')],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }