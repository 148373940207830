<template>
  <div class="registration-form">
    <div class="registration-form__info">
      <div class="registration-form__info-title">Заповніть форму нижче.</div>
      <div class="registration-form__info-text">
        Після того як Ви заповните усі необхідні поля та натисните кнопку, ми
        вишлемо Вам логін та пароль на вказаний номер телефону.
      </div>
    </div>
    <div class="registration-form__warning" v-if="cardIsRegistered">
      <div class="registration-form__warning-title">
        Картка з таким номером вже зареєстрована.
      </div>
      <div class="registration-form__warning-text">
        Будь ласка, перевірте правильність написання або зателефонуйте на гарячу
        лінію 0 800 211 202 (дзвінок безкоштовний з будь-яких телефонних номерів
        в межах України)
      </div>
    </div>
    <div class="registration-form__fields">
      <div
          class="registration-form__field"
          :class="cardRequired.isError ? 'error' : ''"
      >
        <label for="user-card">Номер Вашої картки (11 знаків):</label>
        <div class="registration-form__field-input">
          <masked-input
              v-model="formData.card"
              mask="MD11111111111"
              placeholder="MD 00000000000"
              type="text"
          />
          <div
              class="registration-form__field-error"
              v-if="cardRequired.isError"
          >
            {{ cardRequired.message }}
          </div>
          <div
              class="registration-form__field-icon invalid"
              v-if="cardRequired.isError"
          ></div>
          <div
              class="registration-form__field-icon valid"
              v-if="cardRequired.isValid"
          ></div>
        </div>
      </div>

      <div
          class="registration-form__field"
          :class="surnameRequired.isError ? 'error' : ''"
      >
        <label for="user-last-name">Прізвище</label>
        <div class="registration-form__field-input">
          <input
              type="text"
              v-model="formData.surname"
              id="user-last-name"
              placeholder="Морозенко"
          />
          <div
              class="registration-form__field-error"
              v-if="surnameRequired.isError"
          >
            {{ surnameRequired.message }}
          </div>
          <div
              class="registration-form__field-icon invalid"
              v-if="surnameRequired.isError"
          ></div>
          <div
              class="registration-form__field-icon valid"
              v-if="surnameRequired.isValid"
          ></div>
        </div>
      </div>

      <div
          class="registration-form__field"
          :class="nameRequired.isError ? 'error' : ''"
      >
        <label for="user-first-name">Ім’я</label>
        <div class="registration-form__field-input">
          <input
              type="text"
              v-model="formData.name"
              id="user-first-name"
              placeholder="Ганна"
          />
          <div
              class="registration-form__field-error"
              v-if="nameRequired.isError"
          >
            {{ nameRequired.message }}
          </div>
          <div
              class="registration-form__field-icon invalid"
              v-if="nameRequired.isError"
          ></div>
          <div
              class="registration-form__field-icon valid"
              v-if="nameRequired.isValid"
          ></div>
        </div>
      </div>

      <div
          class="registration-form__field"
          :class="patronymicRequired.isError ? 'error' : ''"
      >
        <label for="user-patronymic">По батькові</label>
        <div class="registration-form__field-input">
          <input
              type="text"
              v-model="formData.patronymic"
              id="user-patronymic"
              placeholder="Степанівна"
          />
          <div
              class="registration-form__field-error"
              v-if="patronymicRequired.isError"
          >
            {{ patronymicRequired.message }}
          </div>
          <div
              class="registration-form__field-icon invalid"
              v-if="patronymicRequired.isError"
          ></div>
          <div
              class="registration-form__field-icon valid"
              v-if="patronymicRequired.isValid"
          ></div>
        </div>
      </div>

      <div
          class="registration-form__field"
          :class="phoneRequired.isError ? 'error' : ''"
      >
        <label for="user-phone">Номер Вашого мобільного телефону</label>
        <div class="registration-form__field-input">
          <masked-input
              v-model="formData.phone"
              mask="\+\38 (111) 111 1111"
              placeholder="+380 ХХ ХХХ ХХ ХХ"
              type="tel"
          />
          <div
              class="registration-form__field-error"
              v-if="phoneRequired.isError"
          >
            {{ phoneRequired.message }}
          </div>
          <div
              class="registration-form__field-icon invalid"
              v-if="phoneRequired.isError"
          ></div>
          <div
              class="registration-form__field-icon valid"
              v-if="phoneRequired.isValid"
          ></div>
        </div>
      </div>

      <div
          class="registration-form__field"
          :class="emailRequired.isError ? 'error' : ''"
      >
        <label for="user-email">
          Ваша електронна адреса (e-mail)
          <span>- Не обов’язково</span>
        </label>
        <div class="registration-form__field-input">
          <input
              type="email"
              v-model="formData.email"
              id="user-email"
              placeholder="example@gmail.com"
          />
          <div
              class="registration-form__field-error"
              v-if="emailRequired.isError"
          >
            {{ emailRequired.message }}
          </div>
          <div
              class="registration-form__field-icon invalid"
              v-if="emailRequired.isError"
          ></div>
          <div
              class="registration-form__field-icon valid"
              v-if="emailRequired.isValid"
          ></div>
        </div>
      </div>

      <div class="registration-form__checkbox">
        <input type="checkbox" id="user-privacy" v-model="userPrivacy"/>
        <label for="user-privacy">
          Я приймаю
          <a href="/terms-and-conditions">Правила та Умови Програми</a> та надаю
          згоду на обробку наданих мною персональних даних на умовах
          <a view href="/Polytyka-konfydentsyalnosty.pdf"
          >Політики конфіденційності</a
          >
        </label>
      </div>

      <div class="registration-form__field">
        <label>Перед тим як відправити дані заповніть капчу</label>
        <!-- <vue-recaptcha sitekey="6LdRq8MZAAAAAAY-01CNm2UlJtI5JQiT5DWZUHlo"></vue-recaptcha> -->
        <!-- <vue-recaptcha sitekey="6LfSu8UZAAAAAJ2xYGkVYe2U7FXgx0_Qozmz1YGx"></vue-recaptcha> -->
        <!-- <vue-recaptcha sitekey="6LfLH88ZAAAAAKtSpRpBHSEZeBi5Jyxmx1MC2MRC"></vue-recaptcha> -->
        <vue-recaptcha
            sitekey="6LeEMtQZAAAAADPU7v86QcLd6XBdSsg5dZNr35eb"
        ></vue-recaptcha>
        <div
            class="registration-form__field-error"
            v-if="recaptchaRequired.isError"
        >
          {{ recaptchaRequired.message }}
        </div>
      </div>

      <div class="registration-form__options">
        <input
            type="button"
            @click="submitForm"
            class="registration-form__submit"
            value="Зареєструватися"
        />
        <div class="registration-form__link">
          Вже є аккаунт?
          <router-link to="/login">Увійти</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions} from "vuex";
import MaskedInput from "vue-masked-input";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "RegistrationForm",
  data() {
    return {
      cardIsRegistered: false,
      userPrivacy: true,
      formData: {
        card: null,
        surname: null,
        name: null,
        patronymic: null,
        phone: null,
        email: null,
        recaptchaToken: null
      },
      cardRequired: {
        isError: false,
        isValid: false,
        message: "Номер картки має бути 11 знаків"
      },
      surnameRequired: {
        isError: false,
        isValid: false,
        message: "Будь ласка, заповніть це поле"
      },
      nameRequired: {
        isError: false,
        isValid: false,
        message: "Будь ласка, заповніть це поле"
      },
      patronymicRequired: {
        isError: false,
        isValid: false,
        message: "Будь ласка, заповніть це поле"
      },
      phoneRequired: {
        isError: false,
        isValid: false,
        message: "Будь ласка, введіть валідний номер"
      },
      emailRequired: {
        isError: false,
        isValid: false,
        message: "Будь ласка, введіть валідну email адресу"
      },
      recaptchaRequired: {
        isError: false,
        isValid: false,
        message: "Будь ласка, заповніть капчу"
      }
    };
  },
  components: {
    MaskedInput,
    VueRecaptcha
  },
  methods: {
    ...mapActions(["userRegistration"]),
    ...mapMutations(["setRegistrationStep", "setRegistrationFormData"]),
    validateCard(card) {
      var reCard = /\d{11}/;
      return reCard.test(card);
    },
    validateName(userName) {
      const reName = /^[А-Яа-яёЁЇїІіЄєҐґ]+$/;
      return reName.test(userName);
    },
    validatePhone(phoneNumber) {
      var rePhone = /^\+?3?8? \((\d{3})\) (\d{3}) (\d{4})$/;
      return rePhone.test(phoneNumber);
    },
    validateEmail(userEmail) {
      var reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reEmail.test(userEmail);
    },
    submitForm() {
      // Validate Card
      if (!this.validateCard(this.formData.card)) {
        this.cardRequired.isError = true;
        window.grecaptcha.reset();
        this.cardRequired.isValid = false;
      } else {
        this.cardRequired.isError = false;
        this.cardRequired.isValid = true;
      }
      // Validate Last Name
      if (!this.validateName(this.formData.surname)) {
        this.surnameRequired.isError = true;
        window.grecaptcha.reset();
        this.surnameRequired.isValid = false;
      } else {
        this.surnameRequired.isError = false;
        this.surnameRequired.isValid = true;
      }
      // Validate First Name
      if (!this.validateName(this.formData.name)) {
        this.nameRequired.isError = true;
        window.grecaptcha.reset();
        this.nameRequired.isValid = false;
      } else {
        this.nameRequired.isError = false;
        this.nameRequired.isValid = true;
      }
      // Validate Sur Name
      if (!this.validateName(this.formData.patronymic)) {
        this.patronymicRequired.isError = true;
        window.grecaptcha.reset();
        this.patronymicRequired.isValid = false;
      } else {
        this.patronymicRequired.isError = false;
        this.patronymicRequired.isValid = true;
      }
      // Validate Phone
      if (!this.validatePhone(this.formData.phone)) {
        this.phoneRequired.isError = true;
        window.grecaptcha.reset();
        this.phoneRequired.isValid = false;
      } else {
        this.phoneRequired.isError = false;
        this.phoneRequired.isValid = true;
      }
      // Validate Email
      if (this.formData.email) {
        if (!this.validateEmail(this.formData.email)) {
          this.emailRequired.isError = true;
          window.grecaptcha.reset();
          this.emailRequired.isValid = false;
        } else {
          this.emailRequired.isError = false;
          this.emailRequired.isValid = true;
        }
      }
      // Validate Captcha
      if (window.grecaptcha.getResponse()) {
        this.recaptchaRequired.isError = false;
        this.formData.recaptchaToken = window.grecaptcha.getResponse();
      } else {
        this.recaptchaRequired.isError = true;
      }
      // If form valid
      if (!this.recaptchaRequired.isError && this.userPrivacy) {
        // if success post
        this.setRegistrationFormData(this.formData);
        this.userRegistration({
          card: this.formData.card,
          surname: this.formData.surname,
          name: this.formData.name,
          patronymic: this.formData.patronymic,
          phone: this.formData.phone,
          email: this.formData.email
        });
      }
      // this.setRegistrationFormData(this.formData);
    }
  }
};
</script>
