<template>
  <main>
    <RegistrationBanner />
    <RegistrationSteps />
  </main>
</template>

<script>
import RegistrationBanner from "@/components/account/registration/RegistrationBanner";
import RegistrationSteps from "@/components/account/registration/RegistrationSteps";

export default {
  name: "RegistrationView",
  components: {
    RegistrationBanner,
    RegistrationSteps
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_registration";
</style>
