<template>
  <div class="registration-confirm">
    <div class="registration-confirm__title">
      Вітаємо Вас у Програмі
      <span>ТерапіяПЛЮС!</span>
    </div>
    <div class="registration-confirm__text">
      Реєстрація пройшла успішно. На зазначений Вами номер телефону
      <strong>{{ getRegistrationFormData.phone }}</strong>
      має прийти повідомлення з персональним логіном та паролем
      <br/>для доступу в Особистий Кабінет
    </div>
    <div class="registration-confirm__options">
      <a href="#" class="registration-confirm__btn" @click.prevent="sendAgain()"
      >Надіслати ще раз</a
      >
      <router-link
          to="/login"
          class="registration-confirm__btn registration-confirm__btn_yellow"
      >Увійти до Особистого кабінету</router-link>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapGetters, mapActions} from "vuex";

export default {
  name: "RegistrationConfrm",
  data() {
    return {
      formData: null
    };
  },
  computed: {
    ...mapGetters(["getRegistrationFormData", "getRegistrationFormData"])
  },
  methods: {
    ...mapMutations(["setRegistrationStep"]),
    ...mapActions(["userRegistration"]),

    sendAgain() {
      this.formData = this.getRegistrationFormData;
      this.userRegistration({
        card: this.formData.card,
        surname: this.formData.surname,
        name: this.formData.name,
        patronymic: this.formData.patronymic,
        phone: this.formData.phone,
        email: this.formData.email
      });
    }
  }
};
</script>

<style></style>
