<template>
  <section class="registration">
    <div class="registration-steps">
      <div class="container">
        <div class="row">
          <div
            class="col-xx-lg-3 col-xx-lg-offset-2 col-sm-4 col-xs"
            :class="getResponsive && getRegistrationStep === 1 ? 'active' : ''"
          >
            <div
              class="registration-steps__item"
              :class="[
                getRegistrationStep === 1 ? 'active' : '',
                getRegistrationStep > 1 ? 'done' : ''
              ]"
            >
              <div class="registration-steps__number">1</div>
              <div class="registration-steps__text">
                Персональні дані
              </div>
            </div>
          </div>
          <div
            class="col-xx-lg-3 col-sm-4 col-xs"
            :class="getResponsive && getRegistrationStep === 2 ? 'active' : ''"
          >
            <div
              class="registration-steps__item registration-steps__item_center"
              :class="[
                getRegistrationStep === 2 ? 'active' : '',
                getRegistrationStep > 2 ? 'done' : ''
              ]"
            >
              <div class="registration-steps__number">2</div>
              <div class="registration-steps__text">Підтвердження</div>
            </div>
          </div>
          <div
            class="col-xx-lg-3 col-sm-4 col-xs"
            :class="getResponsive && getRegistrationStep === 3 ? 'active' : ''"
          >
            <div
              class="registration-steps__item"
              :class="getRegistrationStep === 3 ? 'active' : ''"
            >
              <div class="registration-steps__number">3</div>
              <div class="registration-steps__text">Вхід до кабінету</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="registration-process">
      <div class="container">
        <div class="row">
          <div
            class="col-xx-lg-offset-2"
            :class="
              getRegistrationStep === 2
                ? 'col-xx-lg-5 col-x-lg-6 col-lg-6 col-md-6 col-xs-12'
                : 'col-xx-lg-4 col-x-lg-6 col-lg-6 col-md-6 col-xs-12'
            "
          >
            <RegistrationForm v-if="getRegistrationStep === 1" />
            <RegistrationConfirm v-if="getRegistrationStep === 2" />
            <RegistrationSignIn v-if="getRegistrationStep === 3" />
          </div>
          <div
            class="col-xx-lg-4 col-x-lg-5 col-lg-6 col-md-6 col-xs-12"
            :class="
              getRegistrationStep !== 2
                ? 'col-xx-lg-offset-1 col-x-lg-offset-1'
                : ''
            "
          >
            <div class="registration-process__info">
              <RegistrationInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import RegistrationForm from "@/components/account/registration/RegistrationForm";
import RegistrationConfirm from "@/components/account/registration/RegistrationConfirm";
import RegistrationSignIn from "@/components/account/registration/RegistrationSignIn";
import RegistrationInfo from "@/components/account/registration/RegistrationInfo";
export default {
  name: "RegistrationSteps",
  computed: {
    ...mapGetters(["getRegistrationStep", "getResponsive"])
  },
  components: {
    RegistrationForm,
    RegistrationConfirm,
    RegistrationSignIn,
    RegistrationInfo
  }
};
</script>
